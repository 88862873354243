<template>
    <div class="integral-record">
        <div class="title">
            <div class="sub-title">积分明细</div>
        </div>
        <el-card class="box-card" style="min-height:100%" >
            <div class="integral-surplus flex">
                <div class="left">
                    <span class="text">拥有积分:</span>
                    <span class="count">{{pointsTotal}}</span>
                    <span class="icon"></span>
                </div>
                <div class="right"></div>
            </div>
            <div class="integral-tab">
                <div class="tab-item" :class="[flag===1?'selected':'']" @click="changeFlag(1)">获取的积分</div>
                <div class="tab-item" :class="[flag===2?'selected':'']" @click="changeFlag(2)">消耗的积分</div>
            </div>
            <div>
                <div v-if="state">
                    <div v-if="integralList.length>0">
                        <el-table
                            :data="integralList"
                            border
                            stripe
                            style="width: 100%">
                            <el-table-column
                                prop="time"
                                align="center"
                                label="时间">
                                <template slot-scope="scope">
                                    <span>{{scope.row.createTime}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="detail"
                                align="center">
                                <template slot="header">
                                    <span v-if="flag===1">获得途径</span>
                                    <span v-else>消耗途径</span>
                            </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="本次变化">
                                <template slot-scope="scope">
                                    <span class="integral-success" v-if="flag===1">+{{scope.row.number}}</span>
                                    <span class="integral-error" v-else>-{{scope.row.number}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <app-pagination @handleSizeChange="handleSize" @handleCurrentChange="handleCurrent" :total="total"></app-pagination>
                    </div>
                    <div class="no" v-else>
                        <img src="../../../assets/img/company/loading.png" alt="" srcset="" class="loading-img">
                        <p>正在加载中...</p>
                </div>
                </div>
                <div class="no-list flex" v-else>
                    <img src="../../../assets/img/company/empty.png" alt="" srcset="">
                    <p class="no-tips">空空如也~</p>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import AppPagination from '../../../components/pagination/AppPagination'
import companyRequest from '../../../api/company'
export default {
    components: {
       AppPagination 
    },
    mounted() {
        this.selectMyRecord()
    },
    data() {
        return {
            flag: 1,
            total: 0,
            pointsTotal: 0,
            state: true,
            search: {
                pageNum: 1,
                pageSize: 10,
                type: true //类型 true增加 false消耗
            },
            integralList: [
                // {time: '2020-10-20', source: '签到', change: '8', state: 1},
                // {time: '2020-10-20', source: '查看简历', change: '10', state: 2},
                // {time: '2020-10-20', source: '签到', change: '8', state: 1},
                // {time: '2020-10-20', source: '查看简历', change: '10', state: 2},
                // {time: '2020-10-20', source: '签到', change: '8', state: 1},
                // {time: '2020-10-20', source: '查看简历', change: '10', state: 2},
                // {time: '2020-10-20', source: '签到', change: '8', state: 1},
                // {time: '2020-10-20', source: '查看简历', change: '10', state: 2},
                // {time: '2020-10-20', source: '签到', change: '8', state: 1},
                // {time: '2020-10-20', source: '查看简历', change: '10', state: 2},
                // {time: '2020-10-20', source: '签到', change: '8', state: 1},
                // {time: '2020-10-20', source: '查看简历', change: '10', state: 2},
            ]
        }
    },
    methods: {
        //查询积分
        selectMyRecord() {
            if (this.flag === 1) {
                this.search.type = true
            } else if (this.flag === 2) {
                this.search.type = false
            }
            companyRequest.selectMyRecord(this.search).then((res) => {
                if (res.code === 200) {
                    this.integralList = res.data.list.list
                    this.total = res.data.list.total
                    this.pointsTotal = res.data.all
                    if (this.integralList.length === 0) {
                        this.state = false
                    }
                }
            }).catch((err) => {
                this.state = false
            })
        },
        handleCurrent(num) {
            this.search.pageNum = num
            this.selectMyRecord()
        },
        handleSize(size) {
            this.search.pageSize = size
            this.search.pageNum = 1
            this.selectMyRecord()
        },
        changeFlag(row) {
            if (this.flag === row) {
                return
            }
            this.flag = row
            this.state = true
            this.integralList = []
            this.search.pageNum = 1
            this.selectMyRecord()
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
    .integral-record{
        height: 100%;
        .integral-surplus{
            height: 80px;
            padding-left: 20px;
            align-items: center;
            justify-content: space-between;
            background-color:#ff7533;
            margin-bottom: 20px;
            .left{
                .text{
                    position: relative;
                    top: -4px;
                    color: #ffecbd;
                    font-size: 14px
                }
                .count{
                    font-size: 26px;
                    color: #fffcf1;
                    letter-spacing: 1px;
                    font-weight: 550;
                    margin-left: 13px;
                }
                .icon{
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin-left: 4px;
                    vertical-align: 1px;
                    background-image: url('../../../assets/img/company/personal-icon.png');
                    background-position: 0 -105px;
                }
            }
            .right{
                width: 420px;
                height: 70px;
                background-image: url('../../../assets/img/company/popup-banner-bg.png');
            }
        }
        .integral-tab{
            border-bottom: 2px solid #f0f0f0;
            font-size: 0;
            margin-bottom: 21px;
            .tab-item{
                display: inline-block;
                padding: 0 0 8px;
                border-bottom: 2px solid transparent;
                margin-right: 40px;
                margin-bottom: -2px;
                font-size: 16px;
                line-height: 16px;
                color: #222;
                cursor: pointer;
                &.selected{
                    color: #ff704f;
                    border-bottom: 2px solid #ff704f;
                }
            }
        }
        .integral-success{
            color: $success;
            font-weight: 700;
        }
        .integral-error{
            color: $danger;
            font-weight: 700;
        }
        .no-list{
            margin: 20px 0 200px 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
</style>